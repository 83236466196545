<template>
  <b-modal
    id="modal-opciones-slider"
    ref="my-modal"
    title="Opciones"
    no-close-on-backdrop
    ok-title="Guardar"
    size="lg"
    cancel-variant="outline-secondary"
    cancel-title="Cerrar"
    @cancel="cerrarModal"
    @close="cerrarModal"
    @ok.prevent="validar"
  >
    <b-form>
      <b-row>
        <b-col md="6" class="mb-2">
          <div class="border rounded p-2">
            <h4 class="text-center">
              <b>Imagen</b>
            </h4>
            <div class="text-center">
              <b-form-group label="" label-for="">
                <div class="box-imagen box-imagen1 border rounded">
                  <b-img
                    v-if="imagen"
                    :src="imagen"
                    center
                    fluid
                    thumbnail
                    class="rounded"
                    alt="Seleccione Imagen"
                  />
                </div>
              </b-form-group>
              <b-button
                v-b-modal.modal-seleccionar-imagen-slider
                variant="flat-dark"
                size="sm"
              >
                <feather-icon icon="ImageIcon" class="mr-50" />
                <span class="align-middle">Elegir Imagen</span>
              </b-button>
            </div>
          </div>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Texto #1" label-for="texto1">
            <b-form-textarea
              id="texto1"
              v-model="opciones.texto1"
              placeholder="Texto nro 1"
            />
          </b-form-group>

          <!-- <b-input-group>
            <b-form-input id="nombre" v-model="opciones.color1" />
            <b-input-group-append>
              <b-button variant="outline-primary" size="sm">
                <verte
                  picker="square"
                  model="hex"
                  v-model="opciones.color1"
                ></verte>
              </b-button>
            </b-input-group-append>
          </b-input-group> -->

          <b-form-group label="Texto #2" label-for="texto2">
            <b-form-textarea
              id="texto1"
              v-model="opciones.texto2"
              placeholder="Texto nro 2"
            />
          </b-form-group>

          <!-- <b-input-group>
            <b-form-input id="nombre" v-model="opciones.color2" />
            <b-input-group-append>
              <b-button variant="outline-primary" size="sm">
                <verte
                  picker="square"
                  model="hex"
                  v-model="opciones.color2"
                ></verte>
              </b-button>
            </b-input-group-append>
          </b-input-group> -->

          <b-form-group label="Texto Botón" label-for="boton">
            <b-form-input id="boton" v-model="opciones.texto_boton" />
          </b-form-group>
        </b-col>
      </b-row>
      
      <b-row>
        <b-col cols="12">
          <b-form-group label="Url" label-for="url">
            <b-input-group>
              <b-form-input id="url" v-model="opciones.url_slider" />
              <b-input-group-append>
                <b-button
                  v-b-modal.modal-seleccionar-enlace
                  variant="outline-primary"
                  size="sm"
                >
                  <feather-icon icon="LinkIcon" class="mr-50" />
                  <span class="align-left">Enlaces</span>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Aplicar url a ">
            <b-form-checkbox
              v-for="ubi in ubicacionesurl"
              :key="ubi.id"
              v-model="opciones.aplicarurl"
              :value="ubi.value"
              name="flavour-3a"
              inline
            >
              {{ ubi.titulo }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <!-- <Chrome value="colors"/> -->
      </b-row>
    </b-form>
    <ImagenComponentSlider
      @enviarImagen="recibirImagen"
    ></ImagenComponentSlider>
    <EnlacesComponent @enviarUrl="recibirUrl"></EnlacesComponent>
  </b-modal>
  <!--Componente Imagen -->
</template>
<script>
import axios from "@axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BModal,
  BButton,
  VBModal,
  BAlert,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BRow,
  BCol,
  BForm,
  BImg,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { required } from "@validations";

import ImagenComponentSlider from "@/componentes/ImagenComponentSlider.vue";
import Verte from "verte";
import "verte/dist/verte.css";
import { Chrome } from "vue-color";
import EnlacesComponent from "@/componentes/EnlacesComponent.vue";

export default {
  components: {
    BFormCheckbox,
    BButton,
    BModal,
    BAlert,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BImg,
    BFormSelect,
    BFormTextarea,
    EnlacesComponent,
    Verte,
    Chrome,
    BInputGroup,
    BInputGroupAppend,
    ImagenComponentSlider,
  },
  props: ["accion", "item"],
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  watch: {
    accion: function () {
      this.abrir(this.item);
    },
    nombre: function () {
      this.errores.nombre = [];
    },
    descripcion: function () {
      this.errores.descripcion = [];
    },
  },
  data() {
    return {
      id: null,
      colors: "#194d33",
      opciones: {
        texto1: "",
        color1: "#000000",
        texto2: "",
        color2: "#000000",
        texto_boton: "",
        url_slider: "",
        isurl_text_1:0,
        isurl_text_2:0,
        isurl_imagen:0,
        isurl_boton:0,
        aplicarurl:[],
      },
      ubicacionesurl: [
        {
          id: 1,
          titulo: "Slider",
          value: "slider",
        },
        {
          id: 2,
          titulo: "Texto 1",
          value: "texto_1",
        },
        {
          id: 3,
          titulo: "Texto 2",
          value: "texto_2",
        },
        {
          id: 4,
          titulo: "Botón",
          value: "texto_boton",
        },
      ],

      nombre: "",
      imagen: "",
      url: "",
      descripcion: "",
      estado: 1,
      estadoOpciones: [
        { value: 1, text: "Modificable" },
        { value: 0, text: "No modificable" },
      ],
      titulo: "",
      required,
      submitted: false,
      errores: [],
    };
  },
  created() {
    this.inicializar();
  },
  methods: {
    inicializar() {},
    validar() {
      this.item.imagen_miniatura = this.imagen;
      this.item.texto1 = this.opciones.texto1;
      this.item.texto2 = this.opciones.texto2;
      this.item.color1 = this.opciones.color1;
      this.item.color2 = this.opciones.color2;
      this.item.texto_boton = this.opciones.texto_boton;
      this.item.isurl_text_1=0;
      this.item.isurl_text_2=0;
      this.item.isurl_imagen=0;
      this.item.isurl_boton=0;
      if(this.opciones.aplicarurl.find(elemen=>elemen=='slider'))
        this.item.isurl_imagen=1;
      if(this.opciones.aplicarurl.find(elemen=>elemen=='texto_1'))
        this.item.isurl_text_1=1;
      if(this.opciones.aplicarurl.find(elemen=>elemen=='texto_2'))
        this.item.isurl_text_2=1;
      if(this.opciones.aplicarurl.find(elemen=>elemen=='texto_boton'))
        this.item.isurl_boton=1;
      this.item.aplicarurl = this.opciones.aplicarurl;
      this.item.url_slider = this.opciones.url_slider;
      this.imagen = "";
      this.$emit("cerrarComponente");
      this.$nextTick(() => {
        this.$refs["my-modal"].toggle("#toggle-btn");
      });
    },
    abrir(item) {
     // console.log(item);
      
      
      this.imagen = item.imagen_miniatura;
      this.opciones = Object.assign({}, item);
      this.opciones.aplicarurl=[];
      if(this.opciones.isurl_imagen==1)
        this.opciones.aplicarurl.push('slider');
      if(this.opciones.isurl_text_1==1)
        this.opciones.aplicarurl.push('texto_1');
      if(this.opciones.isurl_text_2==1)
        this.opciones.aplicarurl.push('texto_2');
      if(this.opciones.isurl_boton==1)
        this.opciones.aplicarurl.push('texto_boton');
      //console.log("opciones modal");
      //console.log(this.opciones);
    },
    cerrarModal() {
      this.$emit("cerrarComponente");
    },

    recibirImagen(imagen) {
     // console.log("recibir imagen opciones slider");
     // console.log(imagen);
      this.imagen = imagen.url;
    },
    recibirUrl(enlace) {      
      var itemEnlace=enlace;     
      this.opciones.url_slider=itemEnlace.url;
    },
  },
};
</script>
<style lang="scss">
.box-imagen {
  height: 14rem;
}
.box-imagen img {
  width: 100%;
  height: 100%;
}
.box-imagen1 img {
  object-fit: contain;
}
.vs__open-indicator {
  fill: rgba(60, 60, 60, 0.5);
}
</style>